import React from 'react'
import './About.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {AiFillFolderOpen} from 'react-icons/ai'
const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='' />
          </div>
        </div>
        <div className='about__content'>
        <div className='about__cards'>
          
          <article className='about__card'>
          <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>3+ years Experience</small>
          </article>
          
          <article className='about__card'>
          <FiUsers className='about__icon'/>
            <h5>Clients</h5>
            <small>100+ worldwide</small>
          </article>
          
          <article className='about__card'>
          <AiFillFolderOpen className='about__icon'/>
            <h5>Projects</h5>
            <small>10+ completed</small>
          </article>
        </div>
        <p>
          Hey Myself Parth Pathak. I am currently in my first year of Engineering in Computer Science. I have been working on web
          development for past 9 months and I have done work for many clients. I have good knowledge of HTML,CSS,JS and Frameworks
          like React,Bootstrap.
        </p>
        
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
    
  )
}

export default About