import React from 'react'
import './Services.css'
import {BiCheck} from 'react-icons/bi'
const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className='container service__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Unmetered traffic </p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>100 Websites</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>100 GB SSD Storage</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Free Weekly Backups</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>24/7 Customer Support</p>
            </li>
          </ul>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h3>Web Develpoment</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Manage up to 100 websites.</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>lRegister a personal domain name for free.</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Launch websites quickly with our Website Builder</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>lOptimize your workflow with managed WordPress hosting.</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>et up a professional business email address</p>
            </li>
          </ul>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Unlimited Free SSL</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Free Domain</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Optimized for WordPresslrem df s dsd jndfjn lkjnsdjfjfjfj</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Free Domain</p>
            </li>
            <li>
              <BiCheck className='service__list-icons' />
              <p>Website builder</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services